import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Header from '../components/header'
import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            subtitle
            menuLinks {
            name
            link
         }
          }
        }
      }
    `}
    render={data => (
      <>
        <div>
          <Header
            menuLinks={data.site.siteMetadata.menuLinks}
          />
          {children}
        </div>
      </>
    )}
  />
)

export default Layout
