import * as React from 'react'
import BurgerMenuIcon from '../templates/burger-menu-icon'
import '../styles/header.css'

const BurgerMenu = ({toggleMobileNavScreen, show}) => {

  return (
    <div
      className='burgerIconContainer'
      onClick = {toggleMobileNavScreen}
      onKeyDown = {toggleMobileNavScreen}
      role='presentation'
    >
      {show ?
        <div className='crossIcon'>
          X
        </div>
        :
        <div className='iconContainer'>
          <BurgerMenuIcon />
        </div>
      }
    </div>
  )
}

export default BurgerMenu
