import * as React from 'react'
import ContactForm from './contact-form'
import '../styles/contact-footer.css'
import '../styles/global.css'

const ContactFooter = () => {
  return (
    <div className ='contactFooter'>
      <div className ='centred font'>
        <h2 className ='lighter'>Location</h2>
        <p>Altrincham</p>
        <p>Cheshire, WA14</p>
      </div>
      <div className ='centred font'>
        <h2 className ='lighter'>Contact</h2>
        <p>Kirstie Davidson</p>
        <p>Traditional Reiki Master &amp; Crystal Reiki Master</p>
        <p>
          <a href="tel:+447876794423">(+44) 07876 794423</a>
        </p>
        <p>
          <a href="mailto:healingcrystalsreiki@gmail.com?subject=Contact">healingcrystalsreiki@gmail.com</a>
        </p>
      </div>
      <div>
        <ContactForm />
      </div>
    </div>
  )
}

export default ContactFooter
