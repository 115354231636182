import * as React from 'react'
import '../styles/burger-menu.css'

const BurgerMenuIcon = () => {
  return (
    <div className='burgerIcon'>
      <div className='icon'></div>
      <div className='icon'></div>
      <div className='icon'></div>
    </div>
  )
}

export default BurgerMenuIcon
