import * as React from 'react'
import '../styles/button.css'

const Button = ({label, type='button'}) => {
  return (
    <button className='button' type={type}>{label}</button>
  )
}

export default Button
