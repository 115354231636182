import * as React from 'react'
import Button from '../templates/button'
import '../styles/contact-form.css'

const ContactForm = () => {
  return (
    <div className='formStyles'>
      <div className='formStylesInner'>
        <form method="post" action='https://formspree.io/f/mleanbwv'>
          <label style={{display: 'block'}}>
            <div>Name*</div>
            <input type="text" name="name" className='input' required/>
          </label>
          <label style={{display: 'block'}}>
            <div>Email*</div>
            <input type="email" name="_replyto" className='input' required/>
          </label>
          <label style={{display: 'block'}}>
            <div>Message*</div>
            <textarea type="text" name="message"  className='input textArea' required/>
          </label>
          <div className='centred marginBottom'>
            <Button type='submit' label='Send message to Kirstie'/>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
