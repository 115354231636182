import React, {useState} from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import '../styles/header.css'
import BurgerMenu from '../components/burger-menu'

const Header = ({ menuLinks }) => {
  const [show, setShow] = useState(false);

  const toggleMobileNavScreen = () => setShow(!show)

  return (
    <>
      <header className='header'>
        <BurgerMenu show={show} toggleMobileNavScreen={toggleMobileNavScreen}/>
        <div className={'navContainer' + (show ? ' show' : '')}>
          <nav>
            <ul className='flex nopadding mobileNav'>
              {menuLinks.map(link => (
                <li
                  key={link.name}
                  className='menuListItem'
                >
                  <Link className='link' to={link.link}>
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        {/* <div>
          <Link
            to='/'
            style={{
              color: 'white',
              textDecoration: 'none',
            }}
          >
            <div>
              <img className='logo'
                alt="logo"
                src={`logo.png`} width='14%'
              />
            </div>
          </Link>
        </div> */}
        <div>
          <a className='button' href='/contact'>Get in touch with Kirstie</a>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
